import { Controller } from "stimulus"
import Swiper from "swiper"

export default class extends Controller {
  static targets = [ "swiper", "submitButton" ]

  connect() {
    new Swiper(this.swiperTarget, {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 15,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  }

  upsell() {
    if (this.checkedCount > 0) {
      this.submitButtonTarget.innerText = this.submitButtonTarget.dataset.nextText
    } else {
      this.submitButtonTarget.innerText = this.submitButtonTarget.dataset.skipText
    }
  }

  get checkedCount() {
    return this.swiperTarget.querySelectorAll('input[type="checkbox"]:checked').length
  }

}