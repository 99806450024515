import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    window.addEventListener('scroll', this.infiniteScroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.infiniteScroll)
  }

  infiniteScroll = () => {
    if(this.hasLinkTarget) {
      this.loadNextPage(this.linkTarget)
    }
  }

  loadNextPage(link) {
    let top = link.getBoundingClientRect().top
    if (top < window.innerHeight + 800) {
      $(link).click()
      link.parentNode.removeChild(link)
    }
  }

}