import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "likes" ]

  like(event) {
    if(!event.currentTarget.classList.contains('liked')) {
      event.currentTarget.classList.add('liked')
      this.likesTarget.innerText = this.likesCount + 1
    }
  }

  get likesCount() {
    return parseInt(this.likesTarget.innerText)
  }

}