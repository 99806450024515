import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    let el = this.sourceTarget
    let oldContentEditable = el.contentEditable
    let oldReadOnly = el.readOnly
    let range = document.createRange()
    el.contentEditable = true
    el.readOnly = false
    range.selectNodeContents(el)

    let s = window.getSelection()
    s.removeAllRanges()
    s.addRange(range)

    el.setSelectionRange(0, 999999)
    el.contentEditable = oldContentEditable
    el.readOnly = oldReadOnly

    document.execCommand("copy")
  }

}