import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.fetchSummary()
  }

  fetchSummary() {
    this.loading()
    fetch(this.element.dataset.summaryUrl, {credentials: "same-origin"})
      .then(response => response.text())
      .then(function(html) {
        this.element.innerHTML = html
        this.fetchDeliveryDate()
      }.bind(this))
  }

  fetchDeliveryDate() {
    let element = this.element.querySelector(".checkout-soonest-delivery-date")
    if (element) {
      fetch(element.dataset.soonestDeliveryDateUrl, {credentials: "same-origin"})
        .then((response) => response.text())
        .then(function(date) {
          let momentdate = moment(date)
          element.innerText = momentdate.calendar(null, {
            sameDay: '[vandaag]',
            nextDay: '[morgen] D MMM',
            nextWeek: 'dddd D MMM',
            lastDay: '[gisteren] D MMM',
            lastWeek: '[vorige week] dddd D MMM',
            sameElse: 'D MMM'
          })
        })
    }
  }

  loading() {
  }

}