import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.innerText = this.millisecondsToTime(this.endDate - Date.now())
    setInterval(function() {
      this.element.innerText = this.millisecondsToTime(this.endDate - Date.now())
    }.bind(this), 1000)
  }

  millisecondsToTime(milliseconds) {
    let secs = Math.round(milliseconds / 1000)
    let hours = Math.floor(secs / (60 * 60))

    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)

    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)

    return this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds)
  }

  pad(num) {
    var s = "0" + num
    return s.substr(s.length-2)
  }

  get endDate() {
    return Date.parse(this.element.dataset.countdownDatetime)
  }

}