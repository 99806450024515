import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element[this.identifier] = this
  }

  trackLogin() {
    gtag('event', 'login', {'method': 'Email'})
  }

  trackSignup() {
    gtag('event', 'sign_up', {'method': 'Email'})
  }

  trackSearch(term) {
    gtag('event', 'search', {'search_term': term})
  }
  
  addToWishlist(event) {
    let button = event.currentTarget
    
    // Klaviyo
    var _learnq = window._learnq || []
    var item = {
      "ProductName": button.dataset.name,
      "ProductID": button.dataset.id,
      "Categories": [button.dataset.category, button.dataset.category2],
      "Brand": button.dataset.brand,
      "Price": parseFloat(button.dataset.price),
      "Quantity": 1,
      "URL": `https://${window.location.hostname}${button.dataset.url}`,
      "ImageURL": `https://${window.location.hostname}${button.dataset.imageUrl}`
    }
    _learnq.push(["track", "Added to Wishlist", item])
  }

  selectItem(event) {
    let button = event.currentTarget
    // dataLayer.push({'event': 'select_item', 'ecommerce': {'items': [{item_id: 1}]}})
    
    //     dataLayer.push({
    //       'event': 'select_item',
    //       'ecommerce': {
    //         'items': [{
    //           item_id: button.dataset.id,
    //           item_name: button.dataset.name,
    //           quantity: 1,
    //           price: parseFloat(button.dataset.price),
    //           item_brand: button.dataset.brand,
    //           item_category: button.dataset.category,
    //           item_category2: button.dataset.category2,
    //           item_variant: button.dataset.variant
    //         }]
    //       }
    //     })
    
    // Klaviyo
    var _learnq = window._learnq || []
    var item = {
      "ProductName": button.dataset.name,
      "ProductID": button.dataset.id,
      "Categories": [button.dataset.category, button.dataset.category2],
      "Brand": button.dataset.brand,
      "Price": parseFloat(button.dataset.price),
      "URL": `https://${window.location.hostname}${button.dataset.url}`,
      "ImageURL": `https://${window.location.hostname}${button.dataset.imageUrl}`
    }
    
    _learnq.push(["track", "Viewed Product", item])
    _learnq.push(["trackViewedItem", {
      "Title": item.ProductName,
      "ItemId": item.ProductID,
      "Categories": item.Categories,
      "ImageUrl": item.ImageURL,
      "Url": item.URL,
      "Metadata": {
        "Brand": item.Brand,
        "Price": item.Price
      }
     }])
    // End Klaviyo
    
    gtag('event', 'select_item', {
      currency: "EUR",
      items: [{
        item_id: button.dataset.id,
        item_name: button.dataset.name,
        quantity: 1,
        price: parseFloat(button.dataset.price),
        item_brand: button.dataset.brand,
        item_category: button.dataset.category,
        item_category2: button.dataset.category2,
        item_variant: button.dataset.variant
      }]
    })
  }

  addToCart(event) {
    let button = event.currentTarget
    let price = parseFloat(button.dataset.price)
    
    gtag('event', 'add_to_cart', {
      currency: "EUR",
      value: price,
      items: [{
        item_id: button.dataset.id,
        item_name: button.dataset.name,
        quantity: 1,
        price: price,
        item_brand: button.dataset.brand,
        item_category: button.dataset.category,
        item_category2: button.dataset.category2,
        item_variant: button.dataset.variant
      }]
    })

    fbq('track', 'AddToCart', {
      content_name: button.dataset.name,
      content_ids: [button.dataset.id],
      content_type: 'product',
      value: parseFloat(button.dataset.price),
      currency: 'EUR' 
    })
  }

}