import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "placeholder" ]

  changePlaceholder(event) {
    let text = event.currentTarget.value
    this.placeholderTarget.innerText = text
    this.placeholderTarget.classList.toggle("small-text", text.length > 40)
    this.placeholderTarget.classList.toggle("extra-small-text", text.length > 65)
  }

}