import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.fetchDate()
    if (this.currentLocale == "de") {
      moment.locale("de");
    } else {
      moment.locale('nl');
    }
  }

  fetchDate() {
    fetch(this.element.dataset.url, {credentials: "same-origin"})
      .then((response) => response.text())
      .then(function(date) {
        let momentdate = moment(date)
        
        if (this.currentLocale == "de") {
          let dateString = momentdate.calendar(null, {
            sameDay: '[Heute]',
            nextDay: '[Morgen] D MMMM',
            nextWeek: 'dddd D MMMM',
            lastDay: '[Gestern] D MMMM',
            lastWeek: '[Letzte Woche] dddd D MMMM',
            sameElse: 'dddd D MMMM'
          })
          
          let now = moment()
          let cutoff = moment().hour(17).minutes(0)
          
          if (now.isAfter(cutoff)) {
            this.element.insertAdjacentHTML('afterbegin',`Jetzt bestellen, <span class="text-green">${dateString}</span> im Haus`)
          } else {
            this.element.insertAdjacentHTML('afterbegin', `Vor 17:00 Uhr bestellt, <span class="text-green">${dateString}</span> im Haus`)
          }
        } else {
          let dateString = momentdate.calendar(null, {
            sameDay: '[vandaag]',
            nextDay: '[morgen] D MMMM',
            nextWeek: 'dddd D MMMM',
            lastDay: '[gisteren] D MMMM',
            lastWeek: '[vorige week] dddd D MMMM',
            sameElse: 'dddd D MMMM'
          })
          
          let now = moment()
          let cutoff = moment().hour(17).minutes(0)
          
          if (now.isAfter(cutoff)) {
            this.element.insertAdjacentHTML('afterbegin',`Nu bestellen, <span class="text-green">${dateString}</span> in huis`)
          } else {
            this.element.insertAdjacentHTML('afterbegin', `Vóór 17:00 uur besteld, <span class="text-green">${dateString}</span> in huis`)
          }
        }
        
        // // Black friday melding
        //  this.element.insertAdjacentHTML("beforeend", `<br /><div style="margin-top: 10px; line-height: 14px; color: white; background: #333; border-radius: 30px; display: inline-block; padding: 8px 20px; font-size: 12px; font-weight: 500;">Let op: Mogelijke vertraging bij PostNL</div>`)
      }.bind(this))
  }
  
  get currentLocale() {
    return (navigator.language || navigator.userLanguage).split("-")[0]
  }

}