import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "checkbox" ]

  connect() {
    this.toggleButton()
  }

  toggle() {
    this.toggleButton()
  }

  toggleButton() {
    if (this.checked) {
      this.buttonTarget.classList.remove("button-muted")
      this.buttonTarget.classList.add("button-primary")
      this.buttonTarget.removeAttribute('disabled')
    } else {
      this.buttonTarget.classList.add("button-muted")
      this.buttonTarget.classList.remove("button-primary")
      this.buttonTarget.setAttribute('disabled', 'disabled')
    }
  }

  get checked() {
    return this.checkboxTarget.checked
  }

}