import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    // fetch("/analytics/" + this.orderToken, {
    //   method: "GET",
    //   credentials: "same-origin"
    // }).then((response) => response.json())
    //   .then(function(json) {
    //     gtag("event", "purchase", json)
    //   })
  }
  
  get orderToken() {
    // return this.element.dataset.token
  }
  
}