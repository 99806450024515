/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { TweenLite, TimelineLite, CSSPlugin } from "gsap/TweenMax"
import { animatedScrollTo } from 'es6-scroll-to'

// Mixpanel
window.mixpanel = require('mixpanel-browser')
mixpanel.init("31df5e0f31a551945fe04df8cc433e7e")

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Tailwind
import "css/application"