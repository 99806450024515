import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    this.element['wishlistItem'] = this
    this.toggleHeart()
  }
  
  toggleHeart() {
    this.element.querySelector('a').classList.toggle('filled', this.onWishlist)
  }
  
  get onWishlist() {
    return this.wishlistIds.indexOf(this.id) >= 0
  }
  
  get id() {
    return this.element.dataset.productId
  }
  
  get wishlistIds() {
    if (document.body.dataset.wishlistIds) {
      let wishlistIds = (document.body.dataset.wishlistIds || []).split("-")
      return wishlistIds
    } else {
      return []
    }
  }
  
}