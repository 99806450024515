import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantity", "message" ]

  connect() {
    this.validateStock()
  }

  updateQuantity() {
    this.element.dataset.badge = this.quantity
    this.validateStock()
  }

  validateStock() {
    if (this.quantity > this.stockLevel) {
      if (this.stockLevel == 0) {
        this.messageTarget.innerText = this.oufOfStockLabel
      } else {
        this.messageTarget.innerText = this.stockLevelLabel
      }
    } else {
      this.messageTarget.innerText = ""
    }
  }

  get quantity() {
    return parseInt(this.quantityTarget.value)
  }

  get stockLevel() {
    return parseInt(this.quantityTarget.dataset.stockLevel)
  }
  
  get outOfStockLabel() {
    return this.quantityTarget.dataset.outOfStockLabel
  }
  
  get stockLevelLabel() {
    return this.quantityTarget.dataset.stockLevelLabel
  }

}