import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.stylePrice()
  }

  stylePrice() {
    let price = this.splitPrice(this.price)
    let base = []
    if (this.basePrice) {
      base = this.splitPrice(this.basePrice)
    }

    if (this.promotion) {
      this.element.innerHTML = `<div class="text-line-through">${base[1]}.<small>${base[2]}</small></div><span class="tw-text-white tw-bg-red-700 tw-p-1 tw-rounded tw--mt-1">${price[1]}.<small>${price[2]}</small></span>`
    } else {
      this.element.innerHTML = `${price[1]}.<small>${price[2]}</small>`
    }
  }

  splitPrice(price) {
    return price.match(/^€(\d*)[,|\.](\d{2})\s?$/)
  }

  get basePrice() {
    return this.element.dataset.basePrice
  }

  get price() {
    return this.element.dataset.price
  }

  get promotion() {
    return this.element.hasAttribute('data-promotion')
  }

}