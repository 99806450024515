import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // Track a purchase with order total
    // fbq('track', 'Purchase', {currency: "EUR", value: this.total})
  }

  get total() {
    return parseFloat(this.element.dataset.total)
  }

}