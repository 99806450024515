import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    if(this.checkedIns.includes(this.productId))
      this.drawBadge()
  }

  drawBadge() {
    this.element.innerHTML = "<div class='badge badge-yellow'>Untappd <i class='icon icon-check' style='margin-left: 4px; font-size: 13px; margin-right: -3px'></i></div>"
  }

  get checkedIns() {
    if(document.body.dataset.checkedInIds) {
      return document.body.dataset.checkedInIds.split('-')
    } else {
      return []
    }
  }

  get productId() {
    return this.element.dataset.productId
  }

}