import Swiper from 'swiper'
import { Controller } from "stimulus"
import * as Ladda from 'ladda'
import { animatedScrollTo } from 'es6-scroll-to'

export default class extends Controller {
  static targets = [ "titles", "currentStep", "budget", "exclusivity", "stylesPreset", "lastStep", "resultsTotals", "resultsSwiper", "swiper", "resultName", "resultPrice", "resultDescription", "resultHeader", "backButton", "progress", "total", "beerCount", "loading", "loadingTexts", "resultStyle", "resultVolume", "resultAlcohol", "untappdUsername", "untappdUserInput", "searchUntappdButton", "untappdLoader", "untappdSearchForm", "untappdResult", "untappdBeers", "untappdUserNotFoundMessage", "smaakprofielUntappd", "customStylesList", "message", "messageContainer", "untappdStylePreset" ]

  connect() {
    // Ladda button
    if (this.hasSearchUntappdButtonTarget) {
      this.ladda_button = Ladda.create(this.searchUntappdButtonTarget)
    }

    // Gift
    this.gift = ""

    // Custom styles
    this.customStyles = new Set()

    // Initialize swiper
    this.swiper = new Swiper(this.swiperTarget.querySelector('.swiper-container'), {
      slidesPerView: 2,
      centeredSlides: true,
      grabCursor: true,
      slideToClickedSlide: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        640: { slidesPerView: 4 },
        960: { slidesPerView: 6 }
      }
    })
    this.swiper.on('slideChange observerUpdate', function() {
      let currentSlide = this.swiper.slides[this.swiper.activeIndex]
      this.resultNameTarget.innerText = currentSlide.dataset.name
      this.resultPriceTarget.innerText = currentSlide.dataset.price
      this.resultDescriptionTarget.innerText = currentSlide.dataset.description
      this.resultStyleTarget.innerText = currentSlide.dataset.style
      this.resultVolumeTarget.innerText = currentSlide.dataset.volume
      this.resultAlcoholTarget.innerText = currentSlide.dataset.alcohol
      this.resultHeaderTarget.querySelector('.stars-filled-in').style.width = `${currentSlide.dataset.score}%`
    }.bind(this))

    this.loadingSwiper = new Swiper(this.loadingTextsTarget, {
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2000
      }
    })
  }

  scrollToTop() {
    // Scroll to Y
    animatedScrollTo({to: document.querySelector(".beer-finder-wizard").offsetTop - 200})
  }

  toggleCustomStylesList(event) {
    event.preventDefault()
    $(this.customStylesListTarget).slideToggle()
  }

  setUntappd(event) {
    event.preventDefault()
    this.nextStep()
  }

  setGift(event) {
    this.gift = event.currentTarget.dataset.gift
    if (this.hasUntappdUsernameTarget) {
      this.untappdUsernameTarget.value = ""
    }
    if (this.hasSmaakprofielUntappdTarget) {
      this.smaakprofielUntappdTarget.innerText = ""
    }
    if (this.hasUntappdUserInputTarget) {
      this.untappdUserInputTarget.value = ""  
    }
    this.nextStep()
  }

  searchUntappdUser(event) {
    this.searchUntappdButtonTarget.disabled = true
    this.untappdUserNotFoundMessageTarget.style.display = "none"
    this.ladda_button.start()

    fetch(`/untappd_users.json`, {
      method: 'post',
      headers: {
        'X-CSRF-Token': $.rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify({
        username: this.untappdUserInputTarget.value
      })
    }).then(response => response.json())
      .then(function(json) {
        if (json.status == "ok") {
          this.untappdUsernameTarget.value = json.username
          if (this.hasSmaakprofielUntappdTarget) {
            this.untappdStylePresetTarget.style.display = "block"
            this.smaakprofielUntappdTarget.innerText = json.name
          }
          setTimeout(function() {
            this.searchUntappdButtonTarget.disabled = false
            this.untappdSearchFormTarget.style.display = "none"
            this.untappdResultTarget.style.display = "flex"
            this.untappdBeersTarget.innerText = `${json.beer_count} gedronken bieren`
          }.bind(this), 4000)
        } else {
          this.untappdUserNotFoundMessageTarget.style.display = "block"
          this.untappdUserInputTarget.value = ""
          this.ladda_button.stop()
          this.searchUntappdButtonTarget.disabled = false
        }
      }.bind(this))
  }

  setBudget(event) {
    this.budgetTarget.value = event.currentTarget.dataset.budget
    this.nextStep()
  }

  setExclusivity(event) {
    this.exclusivityTarget.value = event.currentTarget.dataset.exclusivity
    this.nextStep()
  }

  setStylesPreset(event) {
    this.stylesPresetTarget.value = event.currentTarget.dataset.stylesPreset
    this.findBeers()
    this.startLoading()
    this.nextStep()

    clearTimeout(this.timer)
    this.timer = setTimeout(function() {
      this.nextStep()
      this.stopLoading()
    }.bind(this), 4000)
  }

  toggleCustomStyle(event) {
    let checkbox = event.currentTarget

    if (checkbox.checked) {
      this.customStyles.add(checkbox.value)
    } else {
      this.customStyles.delete(checkbox.value)
    }
  }

  startLoading() {
    this.loadingSwiper.autoplay.start()
  }

  stopLoading() {
    this.loadingSwiper.autoplay.stop()
  }

  findBeers() {
    this.resultsSwiperTarget.innerHTML = ''
    fetch(this.element.dataset.url, {
      method: 'post',
      headers: {
        'X-CSRF-Token': $.rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify({
        budget: this.budgetTarget.value,
        exclusivity: this.exclusivityTarget.value,
        styles_preset: this.stylesPresetTarget.value,
        custom_styles: this.customStylesForSubmit,
        gift: this.gift,
        untappd_username: this.hasUntappdUsernameTarget ? this.untappdUsernameTarget.value : ""
      })
    }).then(response => response.json())
      .then(function(json) {
        // Set totals
        this.beerCountTarget.innerText = json.beers.length
        this.totalTarget.innerText = json.total
        this.messageTarget.innerHTML = json.message
        let span = this.messageTarget.querySelector('span')
        if (span && parseInt(json.suggestions) > 0) {
          this.messageContainerTarget.style.display = "block"
          this.element.querySelector(".beer-finder-result-header").classList.add("large-mobile-margin")
          span.innerText = json.suggestions
        } else {
          this.element.querySelector(".beer-finder-result-header").classList.remove("large-mobile-margin")
          this.messageContainerTarget.style.display = "none"
        }

        // Add slides to swiper
        json.beers.forEach(function(beer) {
          this.resultsSwiperTarget.insertAdjacentHTML('beforeend', `
            <div class="swiper-slide" data-id="${beer.id}" data-name="${beer.name}" data-price="${beer.price}" data-description="${beer.description}" data-score="${beer.score}" data-alcohol="${beer.alcohol}" data-volume="${beer.volume}" data-style="${beer.style}">
              <img src="${beer.image_url}" />
            </div>
          `)
        }.bind(this))

        // Update the swiper
        this.swiper.update()
        this.swiper.slideTo(1)
        this.swiper.slideTo(0)
      }.bind(this))
  }

  retry() {
    let currentSlide = this.swiper.slides[this.swiper.activeIndex]
    let untappdUsername = ""
    if (this.hasUntappdUsernameTarget) {
      untappdUsername = this.untappdUsernameTarget.value
    }

    fetch(this.element.dataset.url, {
      method: 'put',
      headers: {
        'X-CSRF-Token': $.rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify({
        product_ids: this.productIds,
        replace_id: currentSlide.dataset.id,
        budget: this.budgetTarget.value,
        exclusivity: this.exclusivityTarget.value,
        styles_preset: this.stylesPresetTarget.value,
        custom_styles: this.customStylesForSubmit,
        gift: this.gift,
        untappd_username: untappdUsername
      })
    }).then(response => response.json())
      .then(function(beer) {
        $(currentSlide.querySelector('img')).fadeOut(300)
        setTimeout(function() {
          currentSlide.innerHTML = `<img src="${beer.image_url}" style="opacity: 0" />`
          setTimeout(function() {
            $(currentSlide.querySelector('img')).animate({opacity: 1}, 300)
          }, 300)
        }.bind(this), 300)
        currentSlide.dataset.name = beer.name
        currentSlide.dataset.price = beer.price
        currentSlide.dataset.description = beer.description
        currentSlide.dataset.score = beer.score
        currentSlide.dataset.alcohol = beer.alcohol
        currentSlide.dataset.volume = beer.volume
        currentSlide.dataset.style = beer.style
        currentSlide.dataset.id = beer.id
        
        this.swiper.update()
        this.resultNameTarget.innerText = beer.name
        this.resultPriceTarget.innerText = beer.price
        this.resultDescriptionTarget.innerText = beer.description
        this.resultStyleTarget.innerText = beer.style
        this.resultVolumeTarget.innerText = beer.volume
        this.resultAlcoholTarget.innerText = beer.alcohol
        this.resultHeaderTarget.querySelector('.stars-filled-in').style.width = `${beer.score}%`

        this.beerCountTarget.innerText = this.swiper.slides.length
        this.totalTarget.innerText = this.totalPrice.format()
      }.bind(this))
  }

  buy() {
    if (!this.buying) {
      // Buy just once!
      this.buying = true
      
      $.ajax({
        url: this.element.dataset.addToCartUrl,
        data: {product_ids: this.productIds},
        method: "POST",
        dataType: "script",
        success: function() {
          this.currentStepTarget.classList.add('no-animation')
          setTimeout(function() {
            this.currentStepTarget.classList.remove('no-animation')
          }.bind(this), 1000)
          this.setStep(1)
          this.buying = false
          this.setTitle()
        }.bind(this)
      })
    }
  }

  share(event) {
    event.preventDefault()
    $.ajax({
      url: this.element.dataset.shareUrl,
      data: {product_ids: this.productIds},
      method: "POST",
      dataType: "script"
    })
  }

  previousStep(event) {
    event.preventDefault()
    if (this.currentStep > 1 && this.currentStep != 6) this.setStep(this.currentStep - 1)
    if (this.currentStep == 6) this.setStep(4)
    this.setTitle()
  }

  nextStep() {
    if (this.currentStep < this.totalSteps) this.setStep(this.currentStep + 1)
    this.setTitle()
  }

  setTitle() {
    this.backButtonTarget.style.display = this.currentStep > 1 ? "block" : "none"
    this.titlesTarget.querySelectorAll('div').forEach(function(title) {
      title.classList.remove('active')
    })
    this.titlesTarget.querySelector(`div:nth-child(${this.currentStep})`).classList.add('active')
  }

  clickTitle(event) {
    let step = parseInt(event.currentTarget.dataset.step)
    if (step < this.currentStep) {
      this.setStep(step)
      this.setTitle()
    }
  }

  setStep(step) {
    this.currentStepTarget.dataset.step = step
    if (step > 4) step = step - 1
    this.progressTarget.style.transform = `scaleX(${(step)/(this.totalSteps - 1)})`
  }

  get totalSteps() {
    return this.currentStepTarget.querySelectorAll('.beer-finder-step').length
  }

  get totalPrice() {
    let prices = Array.from(this.swiper.slides).map(function(slide) {
      return currency(slide.dataset.price, {decimal: ','})
    })
    return prices.reduce((a, b) => a.add(b), currency(0, {formatWithSymbol: true, symbol: '€', separator: '.', decimal: ','}))
  }

  get productIds() {
    return Array.from(this.swiper.slides).map(function(slide) {
      return parseInt(slide.dataset.id)
    })
  }

  get currentStep() {
    return parseInt(this.currentStepTarget.dataset.step)
  }

  get currentStepElement() {
    return this.currentStepTarget.querySelector(`.beer-finder-step:nth-child(${this.currentStep})`)
  }

  get customStylesForSubmit() {
    return Array.from(this.customStyles).join(",")
  }

}