import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "results" ]

  submitQuery = debounce(function(query) {
    let url = new URL(this.element.dataset.autocomplete)
    let params = {query: query}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    fetch(url, {credentials: "same-origin"}).then(response => response.text()).then(function(html) {
      this.resultsTarget.innerHTML = html

      // Send to Google Analytics
      ga('send', 'pageview', `${url.pathname}${url.search}`)

      // Track search in GA4
      document.body.analytics.trackSearch(query)
    }.bind(this))
  }, 300)

  search(event) {
    this.submitQuery(event.currentTarget.value)
  }

}
