import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "list" ]

  toggle(event) {
    $(this.listTarget).slideToggle()
    event.currentTarget.querySelector('.footer-heading-1').classList.toggle("opened")
  }

}