import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "businessFields", "form" ]
  
  connect() {
    this.toggleBusinessFields()
  }
  
  toggleBusinessFields() {
    this.businessFieldsTarget.style.display = this.business ? "block" : "none"
  }
  
  get business() {
    return this.formTarget.elements["customer_account[company_account]"].value == "true"
  }
  
}