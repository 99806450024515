import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "image", "productIdField", "quantityField", "price" ]

  connect() {
    this.quantityFieldTarget.value = this.quantityOfProductId(this.currentProductId)
    if (this.quantity > 0) this.openAddToCart()
  }

  submitForm = debounce(function() {
    // jQuery nastyness
    $(this.formTarget).trigger("submit.rails")
  }, 300)

  addToCart(e) {
    e.preventDefault()

    // Add to quantity
    let quantity = parseInt(this.quantity) + 1
    this.updateQuantity(quantity)
    this.openAddToCart()
    this.submitForm()
  }

  removeFromCart(e) {
    e.preventDefault()

    // Subtract from quantity
    let quantity = parseInt(this.quantity) - 1
    this.updateQuantity(quantity)
    this.openAddToCart()
    this.submitForm()
  }

  updateQuantity(quantity) {
    // Store old quantity
    let old_quantity = this.quantity

    // Max stockLevel, min 0
    quantity = Math.min.apply(Math, [this.stockLevel, Math.max.apply(Math, [0, quantity])])
    this.quantityFieldTarget.value = quantity

    // Fly the image
    if (this.quantity > old_quantity) this.flyImageToCart()
    
    if (this.quantity == old_quantity && this.quantity != 0) {
      this.formTarget.classList.add('max-stock-level-reached')
    } else {
      this.formTarget.classList.remove('max-stock-level-reached')
    }
  }

  openAddToCart() {
    this.formTarget.classList.toggle('open', this.quantityFieldTarget.value > 0)
  }

  flyImageToCart() {
    if (!this.hasImageTarget) return

    let domRect = this.imageTarget.getBoundingClientRect()
    let clone = this.imageTarget.cloneNode()

    // Assign styles
    Object.assign(clone.style, {
      height:   `${domRect.height}px`,
      left:     `${domRect.x}px`,
      position: 'fixed',
      top:      `${domRect.y}px`,
      width:    `${domRect.width}px`,
      zIndex:   200
    })

    // Append to body
    document.body.appendChild(clone)

    // Animate to cart
    let timeline = new TimelineLite()
    timeline.to(clone, .4, {
      height: '65px', 
      left: `${this.domRectCart.x}px`, 
      top: `${this.domRectCart.y}px`, 
      width: '65px'
    }).to(clone, .4, {transform: 'scale(0)'})
  }

  quantityOfProductId(productId) {
    return this.cartContents.filter(function(item) {
      return item.orderable_type == "Spina::Shop::Product" && item.orderable_id == productId
    }).reduce((total, item) => total + item.quantity, 0)
  }

  get quantity() {
    return this.quantityFieldTarget.value
  }

  get currentProductId() {
    return this.productIdFieldTarget.value
  }

  get stockLevel() {
    return this.productIdFieldTarget.dataset.stockLevel
  }

  get cart() {
    return document.getElementById('shopping_cart')
  }

  get domRectCart() {
    return this.cart.getBoundingClientRect()
  }

  get cartContents() {
    return JSON.parse(this.cart.dataset.contents)
  }

}