import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {
    this.element[this.identifier] = this
    this.trackCheckout()
  }
  
  trackCheckout() {
    ga("ec:setAction", "checkout", {
      "step": this.step
    })
    ga("send", "pageview")
  }
  
  get step() {
    return parseInt(this.element.dataset.checkoutStep)
  }
  
}