import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  connect() {
    this.element["quickview"] = this
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.loading()
    this.element.classList.toggle("open")
    if (this.element.classList.contains('open')) {
      document.querySelector('.site-header').classList.add('open-cart')
    } else {
      document.querySelector('.site-header').classList.remove('open-cart')
    }
    this.getShoppingCart()
  }

  open() {
    this.loading()
    this.element.classList.add("open")
    document.querySelector('.site-header').classList.add('open-cart')
    this.getShoppingCart()
  }

  updateQuantity(event) {
    $(event.currentTarget.parentElement).submit()
  }

  deleteItem(event) {
    let orderItemId = event.currentTarget.dataset.id
    let orderItem = document.querySelector(`.quickview-list-item[data-id="${orderItemId}"]`)
    orderItem.parentElement.removeChild(orderItem)
  }

  updateCart() {
    this.loading()

    // Update cart
    fetch(`/shopping_cart/quickview/update_cart`, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      }
    }).then(function(response) {
      if (response.ok) this.getShoppingCart()
    }.bind(this))
  }

  getShoppingCart() {
    fetch(`/shopping_cart/quickview`, {
      credentials: "same-origin"
    }).then(response => response.text())
      .then(function(html) {
        this.dropdownTarget.innerHTML = html
      }.bind(this))
  }

  loading() {
    this.dropdownTarget.innerHTML = `<div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>`
  }

  close(event) {
    this.element.classList.remove("open")
    document.querySelector('.site-header').classList.remove('open-cart')
  }

  outsideClose(event) {
    let rect = this.dropdownTarget.getBoundingClientRect()
    if (event.clientX < rect.x && event.clientX > 0)
      this.close() 
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

}