import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "note"]

  connect() {
    this.toggle()
  }

  toggle(event) {
    if (this.checkboxTarget.checked) {
      this.noteTarget.style.display = "block"
    } else {
      this.noteTarget.style.display = "none"
    }
  }

}