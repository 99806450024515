import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newWishlistInput", "newWishlistCheckbox" ]
  
  changeInput() {
    let filled_in = this.newWishlistInputTarget.value.length > 0
    this.newWishlistCheckboxTarget.checked = filled_in
  }
  
}